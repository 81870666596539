import { CustomQuery, UseContextReturn } from '~/types/core';
import type { AddressInput } from '~/models';

export const createCustomerAddressCommand = {
  execute: async (context: UseContextReturn, params: AddressInput, customQuery: CustomQuery) => {
    const { data } = await context.app.$vsf.$magento.api.createCustomerAddress(params, customQuery);

    return data?.createCustomerAddress ?? {};
  },
};
